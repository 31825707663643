<script setup lang="ts">
import { computed } from 'vue';
import {
	MOBILE_BLOCK_WIDTH,
	DESKTOP_BLOCK_WIDTH,
	MOBILE_BLOCK_PADDING_X,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';
import { objectToCssVariablesDeprecated } from '@zyro-inc/site-modules/utils/objectToCssVariablesDeprecated';
import { BLOCK_STICKY_BAR_MAX_HEIGHT } from '@zyro-inc/site-modules/constants/siteModulesConstants';

interface Props {
	blockStyles?: Record<string, unknown> | null;
}
const props = defineProps<Props>();

const mobileMaxWidthCSSVar = computed(() => `${MOBILE_BLOCK_WIDTH}px`);
const maxWidthCSSVar = computed(() => `${DESKTOP_BLOCK_WIDTH}px`);
const tabletBlockPaddingCSSVar = computed(() => `0 ${MOBILE_BLOCK_PADDING_X}px`);
const computedStyles = computed<Record<string, string>>(() => objectToCssVariablesDeprecated(props.blockStyles) as Record<string, string>);
</script>

<template>
	<div
		class="block-sticky-bar-layout"
		:style="computedStyles"
	>
		<slot />
	</div>
</template>

<style scoped lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block-sticky-bar-layout {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	max-width: v-bind(maxWidthCSSVar);
	min-height: var(--block-min-height);
	width: 100%;
	margin: 0 auto;
	padding: var(--block-padding);

	:deep() {
		.text-box, .text-editor {
			max-height: calc((v-bind(BLOCK_STICKY_BAR_MAX_HEIGHT) * 1px));
			overflow: hidden;
		}
	}
}

@include site-engine-mobile {
	.block-sticky-bar-layout {
		max-width: v-bind(mobileMaxWidthCSSVar);
		min-height: var(--m-block-min-height);
		padding: var(--m-block-padding);
	}
}

// Tablet size
@media screen and (min-width: 361px) and (max-width: $media-mobile) {
	.block-sticky-bar-layout {
			min-height: var(--t-block-min-height);
			padding: v-bind(tabletBlockPaddingCSSVar);
	}
}
</style>
