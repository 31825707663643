<script setup lang="ts">
import {
	ref,
	onMounted,
	computed,
	watch,
	nextTick,
} from 'vue';
import {
	EcommerceRegion,
	EcommerceVariantPrice,
	StripeAddressEvent,
	StripeClickEvent,
	StripeConfirmEvent,
	StripeShippingRateEvent,
	EcommerceProduct,
	EcommerceCartUpdatePayload,
	EcommerceCart,
} from '@zyro-inc/site-modules/types';
import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { getSuccessUrl } from '@zyro-inc/site-modules/utils/ecommerce/cartData';
import { useExpressCheckout } from '@zyro-inc/site-modules/use/useExpressCheckout';

const props = withDefaults(defineProps<{
	isInEditor?: boolean,
	isAddToBagEnabled?: boolean,
	priceData: EcommerceVariantPrice,
	regions?: EcommerceRegion[],
	isPhysical: boolean,
	products: EcommerceProduct[],
	updateCartData?:(payload: EcommerceCartUpdatePayload) => Promise<void>;
	cartId?: string,
	storeId: string,
	stripePublicKey?: string,
	stripeAccountId?: string,
}>(), {
	// TODO: update with https://hostingers.atlassian.net/browse/BE-850;
	// temp values, for testing
	stripePublicKey: 'pk_test_51IQCaMJXmF8zaJiQl5EyZDxgAvrStAfM9P2DvXcI3jlJWjsMW78MA9tcE61dzQ7oqwoIo0Hxmd0xMfdUBqbSvNgx00Pu9WmOib',
	stripeAccountId: 'acct_1PmymdR2CwnEMzeD',
});

const emit = defineEmits<{
	'set-cart-data': [EcommerceCart],
	'create-cart': [],
	'button-click': [],
	'get-regions': [],
}>();

const successUrl = ref('');
const isLoaded = ref(false);

const productPrice = computed(() => props.priceData.sale_amount || props.priceData.amount);

const {
	stripe,
	expressCheckoutElement,
	elements,
	shippingRates,
	getShippingRates,
	handleAddressChangeEvent,
	handleShippingRateChange,
	setupStripeElements,
	updateStripeElement,
	totalPriceAmount,
} = useExpressCheckout({
	productPrice,
	isProductPhysical: computed(() => props.isPhysical), // to keep reactivity since values change on load
	updateCartData: props.updateCartData,
	currencyCode: computed(() => props.priceData.currency.code.toLowerCase()),
});

// Button disabled when in editor
// OR custom field is not filled
const isClickDisabled = computed(() => props.isInEditor || !props.isAddToBagEnabled);
const allowedRegionCodeList = computed(() => props.regions?.flatMap(
	({ countries }) => countries.map((country) => country.countryCode.toUpperCase()),
) || []);
const elementOptions = computed(() => ({
	shippingAddressRequired: props.isPhysical,
	// only pass countries if product is physical, otherwise we allow all countries
	...(props.isPhysical ? {
		allowedShippingCountries: allowedRegionCodeList.value,
	} : {}),
	shippingRates: shippingRates.value,
}));

const initialisePaymentSession = async (event: StripeConfirmEvent): Promise<void> => {
	try {
		const cart = await StoreApi.initiatePaymentSession(props.cartId as string);

		emit('set-cart-data', cart);
	} catch (error) {
		console.error(error);

		event.paymentFailed({
			reason: 'fail',
		});
	}
};

const resolveStripeActions = async () => {
	// Handle click
	expressCheckoutElement.value.on('click', (event: StripeClickEvent) => {
		if (!props.isAddToBagEnabled) {
			return;
		}

		event.resolve(elementOptions.value);
	});
	// Handle shipping address change
	expressCheckoutElement.value.on('shippingaddresschange', async (event: StripeAddressEvent) => {
		await handleAddressChangeEvent(event, props.regions);

		event.resolve({
			shippingRates: shippingRates.value,
		});
	});
	// Handle shipping rate change
	expressCheckoutElement.value.on('shippingratechange', (event: StripeShippingRateEvent) => {
		handleShippingRateChange(event);

		event.resolve();
	});

	// Handle Confirm
	expressCheckoutElement.value.on('confirm', async (event: StripeConfirmEvent) => {
		await handleAddressChangeEvent(event, props.regions);
		await initialisePaymentSession(event);

		// TODO: add paymentIntent returned from paymentSession
		stripe.value?.confirmPayment({
			// clientSecret: paymentIntent,
			elements: elements.value,
			confirmParams: {
				return_url: successUrl.value,
			},
		}).then((result: {error?: Error}) => {
			if (result.error) {
				window.history.pushState({}, '', `${window.location.pathname}?open-modal=EcommerceCheckoutFailed`);
			}
		});
	});
};

const initiateExpressCheckout = async () => {
	await setupStripeElements(props.stripeAccountId, props.stripePublicKey);

	isLoaded.value = true;

	if (props.isInEditor) {
		return;
	}

	successUrl.value = getSuccessUrl(props.products);

	emit('get-regions');

	// If cart is created, it will already be set in global store in Page.vue
	if (!props.cartId) {
		emit('create-cart');

		return;
	}

	getShippingRates(props.cartId);
	resolveStripeActions();
};

onMounted(() => {
	initiateExpressCheckout();
});

watch(() => props.cartId, (newValue, oldValue) => {
	// To prevent watching on initial load in astro and only watch on cases when cartId is actually missing on load
	if (!isLoaded.value) {
		return;
	}

	if (newValue && !oldValue) {
		getShippingRates(props.cartId);
		resolveStripeActions();
	}
});

watch(productPrice, async (newValue, oldValue) => {
	if (newValue !== oldValue && newValue) {
		await nextTick();

		updateStripeElement({
			price: totalPriceAmount.value,
		});
	}
});
</script>

<template>
	<div>
		<div
			ref="expressCheckoutElementContainer"
			class="express-checkout-element"
			:class="{ 'express-checkout-element--disabled': isClickDisabled }"
			@click="$emit('button-click')"
		/>
	</div>
</template>

<style scoped lang="scss">
.express-checkout-element {
	width: 340px;
	margin-top: 8px;

	&--disabled {
		cursor: pointer;

		:deep() {
			iframe {
				pointer-events: none;
			}
		}
	}
}
</style>
