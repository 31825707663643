<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import {
	SiteElements,
	SiteBlockStickyBar,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import BlockStickyBarLayout from '@zyro-inc/site-modules/components/blocks/sticky-bar/BlockStickyBarLayout.vue';
import {
	BLOCK_STICKY_BAR_ID,
	BLOCK_STICKY_BAR_MAX_HEIGHT,
	MEDIA_MOBILE_SCREEN_BREAKPOINT,
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import GridTextBox from '@zyro-inc/site-modules/components/elements/text-box/GridTextBox.vue';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

const CUSTOM_ATTRIBUTES = {
	[DATA_ATTRIBUTE_ANIMATION_ROLE]: DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
};

interface Props {
  data: SiteBlockStickyBar ;
  components: SiteElements;
	isInPreviewMode?: boolean;
	lcp?: {
		type?: string;
		id?: string;
	};
	screenWidth?: number;
}
const props = withDefaults(defineProps<Props>(), {
	screenWidth: 0,
});

const { siteId } = useSiteGlobal();
// @ts-ignore
const { layoutCSSVars } = useBlockLayout({
	blockData: computed(() => props.data),
	siteElements: computed(() => props.components),
	shouldBuildResponsive: !props.isInPreviewMode,
} as any);
const {
	addIntersectionObserver,
	observe,
	intersectionObserver,
	animationClass,
} = useSiteEngineAnimations({
	data: props.data,
	blockId: BLOCK_STICKY_BAR_ID,
});

const blockRef = ref<Element | null>(null);

// @ts-ignore json-validation-related
const backgroundOrigin = computed<string | null>(() => props.data.background?.origin || null);
// @ts-ignore json-validation-related
const backgroundPath = computed<string | null>(() => props.data.background?.path || null);
const isMobile = computed(() => props.screenWidth <= MEDIA_MOBILE_SCREEN_BREAKPOINT);
const isDesktopBlockHidden = computed(() => {
	// these conditions solves the issue where on desktop hidden blocks are invisible in mobile's preview mode
	// @ts-ignore json-validation-related
	if (!props.data.desktop?.isHidden) {
		return false;
	}

	return (props.isInPreviewMode ? props.screenWidth > MEDIA_MOBILE_SCREEN_BREAKPOINT : true);
});
const isMobileBlockHidden = computed(() => {
	// @ts-ignore json-validation-related
	if (!props.data.mobile?.isHidden) {
		return false;
	}

	return (props.isInPreviewMode ? isMobile.value : true);
});
const backgroundSrc = computed(() => getOptimizedSrc(
	backgroundOrigin.value,
	(backgroundPath.value as string),
	siteId.value,
	{
		width: FULL_WIDTH,
	} as any,
));
const backgroundSrcSet = computed(
	() => getFullWidthSrcset(
		backgroundOrigin.value,
		(backgroundPath.value as string),
		siteId.value,
		{
			isMobileFullScreen: false,
		} as any,
	),
);
// @ts-ignore json-validation-related
const isBlockBackgroundFixed = computed(() => props.data.attachment === 'fixed');

const initiateAnimations = async () => {
	addIntersectionObserver();

	if (blockRef.value) {
		await observe(blockRef.value);
	}
};

onMounted(async () => {
	await initiateAnimations();
});

onBeforeUnmount(() => (intersectionObserver.value as any)?.disconnect());

</script>

<template>
	<section
		ref="blockRef"
		class="block-sticky-bar"
		:class="[
			{
				'block-sticky-bar--desktop-hidden': isDesktopBlockHidden,
				'block-sticky-bar--mobile-hidden': isMobileBlockHidden,
			}
		]"
	>
		<!-- json-validation-related any assertions -->
		<BlockBackground
			v-if="data.background"
			class="block-sticky-bar__background"
			:overlay-opacity="(data.background as any)['overlay-opacity']"
			:type="data.background.current"
			:gradient="(data.background as any).gradient"
			:color="data.background.color"
			:src="backgroundSrc"
			:srcset="backgroundSrcSet"
			:is-fixed="isBlockBackgroundFixed"
			:alt="data.background && (data.background as any).alt"
		/>
		<BlockStickyBarLayout
			:block-styles="data.settings.styles"
			:style="layoutCSSVars"
			:class="animationClass"
		>
			<GridTextBox
				v-for="(item, index) in data.items"
				:key="`sticky-element-${index}`"
				class="block-sticky-bar__element"
				text-align="flex-start"
				text-align-mobile="flex-start"
				:content="item.content"
				v-bind="CUSTOM_ATTRIBUTES"
			/>
		</BlockStickyBarLayout>
	</section>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

$block-top-padding: 14px;

.block-sticky-bar {
	position: sticky;
	top: 0;
	z-index: $z-index-site-engine-sticky-bar;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	max-height: calc(v-bind(BLOCK_STICKY_BAR_MAX_HEIGHT) * 1px);
	overflow: hidden;

	&--desktop-hidden {
		@media screen and (min-width: $media-mobile) {
			display: none;
		}
	}

	&__background {
		z-index: -1;
		height: 100%;
	}

	&__element {
		position: relative;
		height: 100%;
		width: 100%;
		text-align: center;
	}
}

@include site-engine-mobile {
	.block-sticky-bar--mobile-hidden {
		display: none;
	}
}
</style>
